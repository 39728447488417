import React, { useState } from "react"
import { navigate } from "gatsby"
import Header from "../components/header/header"

const ContactForm = ({ location }) => {
  const [state, setState] = useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target

    console.log("Submitting form with state:", state)
    console.log("Form name:", form.getAttribute("name"))

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => {
        console.log("Form successfully submitted")
        navigate(form.getAttribute("action"))
      })
      .catch(error => console.log("Form submission error:", error))
  }

  return (
    <div>
      <Header location={location} />
      <div className="flex flex-col items-center mt-20">
        <h1 className="block text-3xl font-bold text-slate-700 mb-8">
          Demande de devis
        </h1>
        <div className="p-8 text-md font-medium text-slate-700">
          Vous avez un projet à réaliser n'hésitez pas à me contacter
        </div>
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          netlify-honeypot="bot-field"
          action="/success"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="contact" />
          <div>
            <p class="hidden">
              <label>
                Don’t fill this out if you’re human: <input name="bot-field" />
              </label>
            </p>
            <label
              className="block text-sm font-medium text-slate-700"
              htmlFor="name"
            >
              Nom prénom
            </label>
            <input
              className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-blue-900"
              type="text"
              id="name"
              name="name"
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label
              className="block text-sm font-medium text-slate-700"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-blue-900"
              id="email"
              type="email"
              name="email"
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label
              className="block text-sm font-medium text-slate-700"
              htmlFor="objet"
            >
              Objet
            </label>
            <input
              className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-blue-900"
              id="objet"
              type="text"
              name="objet"
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label
              className="block text-sm font-medium text-slate-700"
              htmlFor="message"
            >
              Message
            </label>
            <textarea
              className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-blue-900 mb-6"
              id="message"
              name="message"
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button
            className="w-fit shadow-md rounded-full bg-blue-900 text-slate-100 p-2 text-center mt-4 font-semibold"
            type="submit"
          >
            Envoyer le message
          </button>
        </form>
      </div>
    </div>
  )
}

export default ContactForm
